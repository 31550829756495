import React from "react"
import Layout from "@components/layout"
import Meta from "@components/meta"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import Chevron from "@svgs/chevron.svg";
import TopNews from '../components/TopNews'

const Index = () => (
  <Layout>
    <Meta
      desc="木原興業株式会社は、FA・PAシステムの販売、電子・電気機器・計測制御機器やメカトロニクスシステムの販売、空調設備工事など、産業や社会に関わる電気・機械設備に応えるトータルソリューションカンパニーです。"
    />{ }
    <div>
      <div className="relative w-full mt-16">
        <StaticImage className="object-cover w-full h-full" src="../images/top_1_building.jpg" />
        <p className="absolute top-10 left-4 text-xl font-bold lg:top-20 lg:left-12 lg:text-6xl lg:font-normal">電気機器を通じて、<br />様々な産業と社会を支える。</p>
      </div>

      <div className="m-10 lg:my-60">
        <div className="bg-primary py-1 text-center lg:h-250 lg:p-32">
          <p className="text-white mt-5 mb-1 text-xl lg:text-3xl">木原興業株式会社とは</p>
          <hr className="-ml-20 mr-20 lg:m-0" />
          <p className="text-white mt-6 mx-4 lg:px-16 lg:mt-16 lg:text-lg">私たちは、様々な業種の工場、病院や大学などの電気設備を組み上げ、コンスタントに稼働するように最適なシステムを作り上げる、いわば”縁の下の力持ち”な会社です。</p>
          <StaticImage src="../images/top_2_company.jpg" className="-mx-10 my-6 lg:mx-0 lg:mt-16" />
        </div>
      </div>

      <div className="m-10 lg:my-60">
        <div className="bg-secondary py-1 -ml-10 mr-10 text-center lg:h-250 lg:p-32 lg:-ml-40 lg:mr-40">
          <p className="text-black mt-5 mb-1 text-xl lg:text-3xl">明治時代から続く120年の歴史</p>
          <hr className="border-red-500 ml-20 -mr-20 lg:-mr-80 lg:ml-40"></hr>
          <StaticImage src="../images/top_3_history.jpg" className="-mr-10 my-6 lg:my-0 lg:mt-16 lg:h-96" />
          <div className="lg:flex lg:mt-24">
            <p className="text-black mx-4 lg:w-72 lg:flex-grow lg:text-left lg:text-lg lg:mx-28">当社は岡山に根差し、産業用電気機器を中心とした販売を行なう専門商社として成長を続ける会社。様々な業種のお客様と長年取引しており、産業や社会に欠かせない電気設備システムに関するインフラ整備を担っています。</p>
            <Link to="/company" className="lg:w-32">
              <button className="inline-flex items-center my-4 py-1 px-2 border border-primary bg-secondary lg:mt-8">
                <Chevron className="text-primary w-2.5" />
                <p className="ml-3 text-primary">会社概要</p>
              </button>
            </Link>
          </div>
        </div>
      </div>

      <div className="m-10 lg:my-60">
        <div className="bg-primary py-1 ml-10 -mr-10 text-center lg:h-250 lg:ml-40 ">
          <p className="text-white mt-5 mb-1 text-xl lg:text-3xl">事業内容</p>
          <hr className="-ml-20 mr-20"></hr>
          <StaticImage src="../images/top_4_products.jpeg" className="-ml-10 my-6 lg:h-80 lg:-ml-20 lg:mt-24" />
          <div className="text-primary border-solid border-2 border-primary bg-white mr-10 -ml-10 py-2 text-lg lg:mx-12 lg:mt-24 lg:py-4">電気・電子機器の総合商社</div>
          <div className="text-white my-5 mx-4 mb-6 lg:text-lg lg:mx-16">産業や社会のあらゆる分野にかかわる様々な電気・機械設備関連のニーズに応え、幅広く膨大な機器の中から最適なものを選びシステム化、販売するのはもちろん、既存設備のメンテナンスや改良・更新の提案、さらにはお客様の抱える問題点をいち早く把握し解決に導くトータルソリューションカンパニーをめざしています。</div>
          <Link to="/products" className="">
            <button className="inline-flex items-center my-4 py-1 px-2 border border-primary bg-white">
              <Chevron className="text-blockquote w-2.5" />
              <p className="ml-3">取扱い商品</p>
            </button>
          </Link>
        </div>
      </div>

      <div className="lg:my-60">
        <StaticImage src="../images/top_5_recruit.jpg" className="lg:h-96" />
        <div className="bg-primary py-1 text-center lg:h-96">
          <p className="text-white mt-5 mb-1 text-xl lg:text-3xl lg:mt-16">一緒に働く仲間を募集しています</p>
          <p className="text-white mt-6 mx-4 lg:px-20 lg:mt-16">木原興業株式会社は、社員一人ひとりの持てる能力を最大限に引き出し、さらに伸ばします。共に「誇り」を持って仕事ができる仲間を求めています。</p>
          <Link to="/recruit" className="lg:mt-12">
            <button className="inline-flex items-center my-4 py-1 px-2 border border-primary bg-white">
              <Chevron className="text-blockquote w-2.5" />
              <p className="ml-3">採用情報</p>
            </button>
          </Link>
        </div>

      </div>

      <div className="m-10">
        <p className="mt-3 mb-4 text-center text-2xl">お知らせ</p>
        <hr className="ml-20 -mr-10 border-primary mb-4"></hr>
        <TopNews />
      </div>
    </div>
  </Layout>
)

export default Index;