import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
import * as Heading from "@components/heading"

class TopNews extends React.Component {
  render() {
    const { data } = this.props;
    const { edges: posts } = data.allMarkdownRemark;
    let item;
    return (
      <div className="post-list">
        {posts &&
          posts.map(({ node: post }) => {
            if (post.frontmatter.url) {
              item =
                <article key={post.id}>
                  <a className="button text-center" href={post.frontmatter.url} target='_blank' rel="noreferrer">
                    <p>{post.frontmatter.date}</p>
                    <p className="mb-4 text-lg space-y-6">{post.frontmatter.title}</p>
                  </a>
                </article>
            }
            else {
              item =
                <article key={post.id}>
                  <Link className="button text-center" to={post.fields.slug}>
                    <p>{post.frontmatter.date}</p>
                    <p className="mb-4 text-lg space-y-6">{post.frontmatter.title}</p>
                  </Link>
                </article>
            }
            return (
              item
            )
          })
        }
      </div>
    )
  }
}

TopNews.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default () => (
  <StaticQuery
    query={graphql`
      query topNewsQueryAndTopNewsQuery {
        allMarkdownRemark(
          filter: {frontmatter: {templateKey: {eq: "post"}}}
          sort: { order: DESC, fields: [frontmatter___date] }
          limit: 3
        ) {
          edges {
            node {
              fields {
                slug
              }
              id
              frontmatter {
                title
                url
                date(formatString: "YYYY年MM月DD日")
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <TopNews data={data} count={count} />}
  />
)